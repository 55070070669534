import { resizeMediaUrl } from '@wix/photography-client-lib/dist/src/item/itemResizer';
import { PRO_GALLERY } from '../../../constants';
import ProFullscreenWrapper from '../FullscreenWrapper/FullscreenWrapper';
import CommonGalleryWrapper from '../../../common/CommonGalleryWrapper';

export default class ProGallerySantaWrapper extends CommonGalleryWrapper {
  isStoreGallery() {
    return false;
  }
  getFullscreenElement() {
    return ProFullscreenWrapper;
  }

  getSentryDSN() {
    return PRO_GALLERY.SENTRY_DSN;
  }

  getItemResizer() {
    return resizeMediaUrl;
  }

  getArtStoreProps() {
    const props = this.props;
    return {
      cartItemsCount: props.cartItemsCount,
      cartItems: props.cartItems,
      artStoreOOIApi: props.artStoreOOIApi,
      biToken: props.biToken,
      AnonymousUserId: props.AnonymousUserId,
      connectedProviders: props.connectedProviders,
      compId: props.id,
      pageId: props.pageId,
      styleId: props.styleId,
      galleryId: props.galleryId,
      getMoreItems: props.getMoreItems,
      scrollTo: props.scrollTo,
      onLinkNavigation: props.onLinkNavigation,
    };
  }
}